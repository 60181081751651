import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import Logo from "../images/logofinal.svg";
import { FaFacebookSquare, FaYoutube } from "react-icons/fa";
import Hero from "../components/Home/hero";

import { Location } from "@reach/router";

const pageNameToUrl = {
  Home: "/",
  "About Us": "/about/",
  Treatments: "/mens-health",
  "Q & A": "/faq/",
  Science: "/science/",
  "Contact Us": "/contact-us/"
};
const otherNameToUrl = {
  Treatments: "/mens-health/"
};

const pageNames = [
  "Home",
  "About Us",
  "Treatments",
  "Q & A",
  "Science",
  "Contact Us"
];

const treatmentLinks = [
  { name: "Neuropathy", path: "/treatments/" },
  { name: "Men's Health", path: "/mens-health/" },
  { name: "Technology", path: "/treatments/technology" },
  { name: "Aesthetics", path: "/treatments/aesthetics" },
  {
    name: "Women's Sexual Wellness",
    path: "/treatments/womens-sexual-wellness"
  }
];

const Header = ({ showHero, location }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phoneStyled
          phoneLink
          facebookLink
          youtubeLink
        }
      }
    }
  `);
  const { site } = data;
  const pathName = location.pathname;

  const [toggleSideNav, setToggleSideNav] = useState(false);
  const [showTreatmentDropdown, setShowTreatmentDropdown] = useState(false);

  const PageLinks = pageNames.map((pageName, index) => {
    const urlForPage = pageNameToUrl[pageName];
    const isCurrentPage =
      pageNameToUrl[pageName] === pathName ||
      pageNameToUrl[pageName] === pathName + "/";
    const isSubPage =
      otherNameToUrl[pageName] === pathName ||
      otherNameToUrl[pageName] === pathName + "/";

    const isTreatmentsPage = pathName === "/treatments/";
    const isTreatmentSubPage =
      pathName.startsWith("/treatments/") ||
      pathName.startsWith("/mens-health/");
    const isServicesPage = pathName === "/mens-health/";
    if (pageName === "Treatments") {
      return (
        <div
          className="relative inline-block text-left"
          onMouseEnter={() => setShowTreatmentDropdown(true)}
          onMouseLeave={() => setShowTreatmentDropdown(false)}
        >
          <Link key={"link1" + index} className="nav-link " to={urlForPage}>
            <li
              className={`${
                isCurrentPage || isSubPage
                  ? "bg-blue-500 text-white hover:bg-blue-500 hover:text-white"
                  : "hover:bg-blue-500 hover:text-white"
              }`}
            >
              <nobr>{pageName}</nobr>
            </li>
          </Link>
          {showTreatmentDropdown ? (
            <div
              class="origin-top-right absolute right-0 w-38 text-center 
          rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 
          focus:outline-none z-50"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1 " role="none">
                <Link
                  to="/treatments/"
                  className={` block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white ${
                    isTreatmentsPage
                      ? "bg-blue-500 text-white"
                      : "text-gray-900"
                  }`}
                  role="menuitem"
                  tabIndex="-1"
                >
                  Neuropathy
                </Link>
                <Link
                  to="/mens-health/"
                  className={` block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white ${
                    pathName === "/mens-health"
                      ? "bg-blue-500 text-white"
                      : "text-gray-900"
                  }`}
                  role="menuitem"
                  tabIndex="-2"
                >
                  Men's Health
                </Link>
                <Link
                  to="/treatments/technology"
                  className={` block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white ${
                    pathName === "/treatments/technology"
                      ? "bg-blue-500 text-white"
                      : "text-gray-900"
                  }`}
                  role="menuitem"
                  tabIndex="-3"
                >
                  Technology
                </Link>
                <Link
                  to="/treatments/aesthetics"
                  className={` block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white ${
                    pathName === "/treatments/aesthetics"
                      ? "bg-blue-500 text-white"
                      : "text-gray-900"
                  }`}
                  role="menuitem"
                  tabIndex="-3"
                >
                  Aesthetics
                </Link>
                <Link
                  to="/treatments/womens-sexual-wellness"
                  className={` block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white ${
                    pathName === "/treatments/womens-sexual-wellness"
                      ? "bg-blue-500 text-white"
                      : "text-gray-900"
                  }`}
                  role="menuitem"
                  tabIndex="-4"
                >
                  Women's Sexual Wellness
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <Link key={"link1" + index} className="nav-link  mx-2" to={urlForPage}>
          <li
            className={`${
              isCurrentPage
                ? "bg-blue-500 text-white hover:text-white hover:bg-blue-500"
                : "hover:text-white hover:bg-blue-500"
            }`}
          >
            <nobr>{pageName}</nobr>
          </li>
        </Link>
      );
    }
  });

  const PageLinksTablet = pageNames.map((pageName, index) => {
    const urlForPage = pageNameToUrl[pageName];
    const isCurrentPage =
      pageNameToUrl[pageName] === pathName ||
      pageNameToUrl[pageName] === pathName.substring(0, pathName.length - 1);
    return (
      <Link key={"link2" + index} className="nav-link" to={urlForPage}>
        <li
          style={{ flexBasis: "25%" }}
          className={`${isCurrentPage ? "active" : ""}`}
        >
          <nobr>{pageName}</nobr>
        </li>
      </Link>
    );
  });

  const desktopHeader = (
    <header className="w-full border-b-2">
      <div className="flex flex-col items-end w-full h-full">
        <div
          className="flex items-center content-center"
          style={{ fontSize: 17, padding: 10, color: "#747474" }}
        >
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={site.siteMetadata.youtubeLink}
          >
            <FaYoutube
              style={{
                fontSize: "1.75rem",
                marginRight: "0.75rem",
                color: "rgb(255, 0, 0)"
              }}
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={site.siteMetadata.facebookLink}
          >
            <FaFacebookSquare
              style={{
                fontSize: "1.5rem",
                marginRight: "0.6rem",
                color: "rgb(59, 134, 197)"
              }}
            />
          </a>
          <a href={`tel:${data.site.siteMetadata.phoneLink}`} className="flex">
            <p className="mr-1">Call Us Today!</p>
            <p className="text-blue-500 font-medium">
              {site.siteMetadata.phoneStyled}
            </p>
          </a>
        </div>
      </div>
      <div className="flex flex-wrap flex-row  sm:justify-center xl:justify-between">
        <Logo
          style={{ height: 80, marginTop: 12, marginLeft: 12 }}
          alt="Multnomah Medical Clinic logo"
        />
        <nav className="desktop-nav w-full max-w-lg md:hidden text-center text-gray-900 text-md uppercase font-bold">
          <ul className="flex flex-wrap justify-between content-between">
            {PageLinksTablet}
          </ul>
        </nav>
        <nav className="desktop-nav hidden max-w-4xl md:block md:ml-5">
          <ul className="text-center text-gray-900 text-md uppercase font-bold flex flex-wrap justify-evenly xl:flex-no-wrap">
            {PageLinks}
          </ul>
        </nav>
      </div>
      {/*<div
        className="border-t-2 text-md font-semibold border-black text-gray-800 pl-6"
        style={{
          borderTop: "1px solid rgba(198, 246, 213, 0.3)",
          backgroundColor: "#ccffcc",
          paddingLeft: "1.5rem !important"
        }}
      >
        0% interest financing now available!<sup>*</sup>
      </div>*/}
    </header>
  );

  const mobileHeader = (
    <header
      className={`w-full h-full border-b-2 ${pathName === "/" ? "" : "mb-12"}`}
    >
      <div className="flex items-center justify-between p-2">
        <div className="flex">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={site.siteMetadata.facebookLink}
          >
            <FaFacebookSquare
              style={{
                fontSize: "1.75rem",
                marginRight: "0.6rem",
                marginBottom: "-0.3rem",
                opacity: 0.8,
                color: "rgb(59, 134, 197)"
              }}
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={site.siteMetadata.youtubeLink}
          >
            <FaYoutube
              style={{
                fontSize: "2rem",
                marginRight: "0.5rem",
                marginBottom: "-0.425rem",
                opacity: 0.8,
                color: "rgb(255, 0, 0)"
              }}
            />
          </a>
        </div>
        <div className="relative mt-2 mr-2">
          <button
            onClick={() => setToggleSideNav(!toggleSideNav)}
            className={`hamburger hamburger--spin ${
              toggleSideNav ? "is-active" : ""
            }`}
            type="button"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      <Link to="/">
        <Logo style={{ maxHeight: 72 }} alt="Multnomah Medical Clinic logo" />
      </Link>
      {/*<div
        className="border-t-2 text-md font-semibold border-black text-gray-800 pl-4"
        style={{
          borderTop: "1px solid rgba(198, 246, 213, 0.3)",
          backgroundColor: "#ccffcc",
          paddingLeft: "1.5rem !important"
        }}
      >
        0% interest financing now available!<sup>*</sup>
      </div>*/}
    </header>
  );

  return (
    <>
      <div
        className={`hidden relative sm:block ${
          pathName === "/" ? "" : "mb-12"
        }`}
      >
        {showHero ? (
          <div className="h-screen w-full flex flex-col">
            <div className="md-flex">{desktopHeader}</div>
            <div style={{ flexBasis: "89%" }}>
              <Hero />
            </div>
          </div>
        ) : (
          desktopHeader
        )}
      </div>
      <div className="block sm:hidden">
        {showHero ? (
          <div className="h-screen w-full flex flex-col">
            <div style={{ flexBasis: "15%" }}>{mobileHeader} </div>
            <div style={{ flexBasis: "85%" }}>
              <Hero />
            </div>
          </div>
        ) : (
          mobileHeader
        )}
        <div style={{ display: "flex" }}>
          <div
            className="mobile-menu"
            style={
              toggleSideNav
                ? { transform: "translateX(105vw)" }
                : { transform: "translateX(0)" }
            }
          >
            <nav role="navigation">
              <ul style={{ margin: 0 }}>
                <li className="mobile-menu-item">
                  <Link
                    to="/"
                    className="mobile-menu-link"
                    activeClassName="active"
                  >
                    Home
                  </Link>
                </li>
                <li className="mobile-menu-item">
                  <Link
                    to="/about/"
                    className="mobile-menu-link"
                    activeClassName="active"
                  >
                    About Us
                  </Link>
                </li>
                <li className="mobile-menu-item">
                  <Link
                    to="/treatments/"
                    className="mobile-menu-link"
                    activeClassName="active"
                  >
                    Neuropathy & Joint Pain
                  </Link>
                </li>
                <li className="mobile-menu-item">
                  <Link
                    to="/mens-health/"
                    className="mobile-menu-link"
                    activeClassName="active"
                  >
                    Men's Health
                  </Link>
                </li>
                <li className="mobile-menu-item">
                  <Link
                    to="/treatments/technology"
                    className="mobile-menu-link"
                    activeClassName="active"
                  >
                    Technology
                  </Link>
                </li>
                <li className="mobile-menu-item">
                  <Link
                    to="/treatments/aesthetics"
                    className="mobile-menu-link"
                    activeClassName="active"
                  >
                    Aesthetics
                  </Link>
                </li>
                <li className="mobile-menu-item">
                  <Link
                    to="/treatments/womens-sexual-wellness"
                    className="mobile-menu-link"
                    activeClassName="active"
                  >
                    Women's Sexual Wellness
                  </Link>
                </li>
                <li className="mobile-menu-item">
                  <Link
                    to="/faq/"
                    className="mobile-menu-link"
                    activeClassName="active"
                  >
                    Q & A
                  </Link>
                </li>
                <li className="mobile-menu-item">
                  <Link
                    to="/science/"
                    className="mobile-menu-link"
                    activeClassName="active"
                  >
                    Science
                  </Link>
                </li>
                {/*<li className="mobile-menu-item">
                  <Link to="/blog/" className="mobile-menu-link" activeClassName="active">Blog</Link>
                </li>*/}
                <li className="mobile-menu-item">
                  <Link
                    to="/contact-us/"
                    className="mobile-menu-link"
                    activeClassName="active"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div
            onClick={() => setToggleSideNav(false)}
            className="mobile-menu-closer"
            style={
              toggleSideNav
                ? { transform: "translateX(105vw)" }
                : { transform: "translateX(0)" }
            }
          ></div>
        </div>
      </div>
    </>
  );
};

export default props => (
  <Location>
    {locationProps => <Header {...locationProps} {...props} />}
  </Location>
);
